"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
window.FILTERS_LABELS_DICT = {};
window.REVERSE_FILTER_DICT = {};
(function () {
  var HAS_IMAGE_TEXT_LABEL = 'Publicações com transcrição imagem via STILINGUE Eyes';
  var IS_IMAGE_MATCH_ONLY = 'Somente publicações coletadas via STILINGUE Eyes';
  window.FILTERS_DATA = [{
    'Nomenclatura': {
      data: [{
        value: 'date_range',
        label: 'Intervalo de datas'
      }, {
        value: 'annotation_range',
        label: 'Intervalo de datas de classificação'
      }, {
        value: 'names',
        label: 'Grupos'
      }, {
        value: 'themes',
        label: 'Temas'
      }, {
        value: 'occupations',
        label: 'Interesses'
      }, {
        value: 'terms',
        label: 'Termos'
      }, {
        value: 'channels',
        label: 'Canais'
      }, {
        value: 'integrations',
        label: 'Integrações'
      }, {
        value: 'favorite',
        label: 'Checado'
      }, {
        value: 'polarities',
        label: 'Sentimento'
      }, {
        value: 'polarity',
        label: 'Sentimento'
      }, {
        value: 'spam',
        label: 'Remover'
      }, {
        value: 'genders',
        label: 'Gênero'
      }, {
        value: 'gender',
        label: 'Gênero'
      }, {
        value: 'locations',
        label: 'Locais'
      }, {
        value: 'types',
        label: 'Tipos de publicações'
      }, {
        value: 'devices',
        label: 'Dispositivos'
      }, {
        value: 'tags',
        label: 'Tags'
      }, {
        value: 'query',
        label: 'Contendo texto'
      }, {
        value: 'uids',
        label: 'Usuários'
      }, {
        value: 'city',
        label: 'Cidade'
      }, {
        value: 'state',
        label: 'Estado'
      }, {
        value: 'country',
        label: 'País'
      }, {
        value: 'region',
        label: 'Região'
      }, {
        value: 'macro_region',
        label: 'Mesorregiões IBGE'
      }, {
        value: 'micro_region',
        label: 'Microrregiões IBGE'
      }, {
        value: 'posted_hour',
        label: 'Publicado na hora'
      }, {
        value: 'posted_week_day',
        label: 'Publicado no dia da semana'
      }, {
        value: 'posted_day',
        label: 'Publicado no dia do mês'
      }, {
        value: 'posted_month',
        label: 'Publicado no mês'
      }, {
        value: 'annotated',
        label: 'Publicações anotadas'
      }, {
        value: 'deleted',
        label: 'Publicações removidas'
      }, {
        value: 'links',
        label: 'Links'
      }, {
        value: 'descriptors',
        label: 'Buscas'
      }, {
        value: 'hashtags',
        label: 'Hashtags'
      }, {
        value: 'domains',
        label: 'Domínios'
      }, {
        value: 'selection',
        label: 'Arquivo'
      }, {
        value: 'usernames',
        label: 'Usuários'
      }, {
        value: 'langs',
        label: 'Língua'
      }, {
        value: 'bio',
        label: 'Termos na bio'
      }, {
        value: 'likes',
        label: 'Curtidas/Gostei'
      }, {
        value: 'shares',
        label: 'Compartilhamentos'
      }, {
        value: 'views',
        label: 'Views'
      }, {
        value: 'reach',
        label: 'Alcance'
      }, {
        value: 'followers',
        label: 'Seguidores/Inscritos'
      }, {
        value: 'comments',
        label: 'Comentários'
      }, {
        value: 'attractiveness',
        label: 'Atratividade'
      }, {
        value: 'affinity',
        label: 'Afinidade'
      }, {
        value: 'edited_by',
        label: 'Classificado por'
      }, {
        value: 'annotators',
        label: 'Classificado por'
      }, {
        value: 'geo_coverage',
        label: 'Abrangência'
      }, {
        value: 'highlight_type',
        label: 'Tipo de destaque'
      }, {
        value: 'tier',
        label: 'Tier'
      }, {
        value: 'section',
        label: 'Seção'
      }, {
        value: 'sac_critical_level',
        label: 'Criticidade'
      }, {
        value: 'sac_status',
        label: 'Status'
      }, {
        value: 'replier',
        label: 'Respondente'
      }, {
        value: 'sac_replier',
        label: 'Respondente'
      }, {
        value: 'ids',
        label: 'Publicações com id'
      }, {
        value: 'post_ids',
        label: 'Publicações com id'
      }, {
        value: 'pids',
        label: 'Publicações com id'
      }, {
        value: 'sac_call_ids',
        label: 'Conversas com id'
      }, {
        value: 'pages',
        label: 'Páginas'
      }, {
        value: 'sac_type',
        label: 'Tipos de interação'
      }, {
        value: 'proprietary_posts',
        label: 'Publicações proprietárias'
      }, {
        value: 'sac_only_conversation',
        label: 'Somente primeiras interações'
      }, {
        value: 'dark_post',
        label: 'Tipo de publicação'
      }, {
        value: 'routing_id',
        label: 'Roteamentos'
      }, {
        value: 'rating',
        label: 'Notas'
      }, {
        value: 'has_image_text',
        label: HAS_IMAGE_TEXT_LABEL
      }, {
        value: 'is_image_match_only',
        label: IS_IMAGE_MATCH_ONLY
      }]
    }
  }, {
    'Intervalo de datas': {
      min: 0,
      max: 0,
      domain: 'date'
    }
  }, {
    'Grupos': {
      data: [],
      domain: 'invisible'
    }
  }, {
    'Temas': {
      data: [],
      domain: 'invisible'
    }
  }, {
    'Comunidades': {
      'data': [{
        'value': 'Viagens / Aeroporto / Linhas Aéreas',
        'label': 'Viagens / Aeroporto / Linhas Aéreas'
      }, {
        'value': 'Artes & Entretenimento',
        'label': 'Artes & Entretenimento'
      }, {
        'value': 'Viagens / Vida Noturna',
        'label': 'Viagens / Vida Noturna'
      }, {
        'value': 'Esportes / Organização / Evento Esportivo',
        'label': 'Esportes / Organização / Evento Esportivo'
      }, {
        'value': 'Artes & Entretenimento / Programas de TV / Séries',
        'label': 'Artes & Entretenimento / Programas de TV / Séries'
      }, {
        'value': 'Hobbies & Interesses',
        'label': 'Hobbies & Interesses'
      }, {
        'value': 'Finanças / Negócios / Liderança',
        'label': 'Finanças / Negócios / Liderança'
      }, {
        'value': 'Mídia / Rede Social',
        'label': 'Mídia / Rede Social'
      }, {
        'value': 'Mídia / Blog, Site e Portal da Internet',
        'label': 'Mídia / Blog, Site e Portal da Internet'
      }, {
        'value': 'Viagens / Lugar / Lugar Turístico',
        'label': 'Viagens / Lugar / Lugar Turístico'
      }, {
        'value': 'Sociedade / ONGs & Ativismo Social',
        'label': 'Sociedade / ONGs & Ativismo Social'
      }, {
        'value': 'Comidas & Bebidas',
        'label': 'Comidas & Bebidas'
      }, {
        'value': 'Sociedade',
        'label': 'Sociedade'
      }, {
        'value': 'Notícias',
        'label': 'Notícias'
      }, {
        'value': 'Notícias / Jornalismo Investigativo',
        'label': 'Notícias / Jornalismo Investigativo'
      }, {
        'value': 'Ciências & Meio Ambiente / História',
        'label': 'Ciências & Meio Ambiente / História'
      }, {
        'value': 'Esportes / Modalidades Esportivas',
        'label': 'Esportes / Modalidades Esportivas'
      }, {
        'value': 'Mídia / Rádio',
        'label': 'Mídia / Rádio'
      }, {
        'value': 'Esportes / Torcida Organizada / Times',
        'label': 'Esportes / Torcida Organizada / Times'
      }, {
        'value': 'Sociedade / Marcas de consumo',
        'label': 'Sociedade / Marcas de consumo'
      }, {
        'value': 'Moda & Estilo',
        'label': 'Moda & Estilo'
      }, {
        'value': 'Hobbies & Interesses / Outros Jogos',
        'label': 'Hobbies & Interesses / Outros Jogos'
      }, {
        'value': 'Sociedade / Estilo de Vida',
        'label': 'Sociedade / Estilo de Vida'
      }, {
        'value': 'Educação / Congressos e Palestras',
        'label': 'Educação / Congressos e Palestras'
      }, {
        'value': 'Finanças / Negócios / Conglomerados',
        'label': 'Finanças / Negócios / Conglomerados'
      }, {
        'value': 'Finanças / Negócios',
        'label': 'Finanças / Negócios'
      }, {
        'value': 'Direito, Governo e Política / Governo / Política',
        'label': 'Direito, Governo e Política / Governo / Política'
      }, {
        'value': 'Artes & Entretenimento / Livros e Literatura',
        'label': 'Artes & Entretenimento / Livros e Literatura'
      }, {
        'value': 'Moda & Estilo / Beleza / Cosméticos',
        'label': 'Moda & Estilo / Beleza / Cosméticos'
      }, {
        'value': 'Tecnologia & Computação / Eletrônicos',
        'label': 'Tecnologia & Computação / Eletrônicos'
      }, {
        'value': 'Tecnologia & Computação',
        'label': 'Tecnologia & Computação'
      }, {
        'value': 'Comidas & Bebidas / Restaurantes',
        'label': 'Comidas & Bebidas / Restaurantes'
      }, {
        'value': 'Esportes',
        'label': 'Esportes'
      }, {
        'value': 'Sociedade / Feminismo / Machismo',
        'label': 'Sociedade / Feminismo / Machismo'
      }, {
        'value': 'Saúde & Fitness',
        'label': 'Saúde & Fitness'
      }, {
        'value': 'Finanças / Negócios / Startups',
        'label': 'Finanças / Negócios / Startups'
      }, {
        'value': 'Família & Paternidade / Bebê / Criança',
        'label': 'Família & Paternidade / Bebê / Criança'
      }, {
        'value': 'Educação',
        'label': 'Educação'
      }, {
        'value': 'Mídia / Impresso',
        'label': 'Mídia / Impresso'
      }, {
        'value': 'Artes & Entretenimento / Música',
        'label': 'Artes & Entretenimento / Música'
      }, {
        'value': 'Educação / Instituições Educacionais',
        'label': 'Educação / Instituições Educacionais'
      }, {
        'value': 'Artes & Entretenimento / Artes Cênicas',
        'label': 'Artes & Entretenimento / Artes Cênicas'
      }, {
        'value': 'Automotivo',
        'label': 'Automotivo'
      }, {
        'value': 'Casa & Jardim',
        'label': 'Casa & Jardim'
      }, {
        'value': 'Hobbies & Interesses / Gaming',
        'label': 'Hobbies & Interesses / Gaming'
      }, {
        'value': 'Finanças / Negócios / FMCG',
        'label': 'Finanças / Negócios / FMCG'
      }, {
        'value': 'Casa & Jardim / Artigos Domésticos',
        'label': 'Casa & Jardim / Artigos Domésticos'
      }, {
        'value': 'Carreiras / Profissão / Cargo',
        'label': 'Carreiras / Profissão / Cargo'
      }, {
        'value': 'Carreiras',
        'label': 'Carreiras'
      }, {
        'value': 'Viagens / América Latina',
        'label': 'Viagens / América Latina'
      }, {
        'value': 'Sociedade / Cultura',
        'label': 'Sociedade / Cultura'
      }, {
        'value': 'Hobbies & Interesses / Revistas / Revista em Quadrinhos',
        'label': 'Hobbies & Interesses / Revistas / Revista em Quadrinhos'
      }, {
        'value': 'Gestão & Empreendedorismo',
        'label': 'Gestão & Empreendedorismo'
      }, {
        'value': 'Mídia',
        'label': 'Mídia'
      }, {
        'value': 'Mídia / Nova Mídia',
        'label': 'Mídia / Nova Mídia'
      }, {
        'value': 'Sociedade / Erótico',
        'label': 'Sociedade / Erótico'
      }, {
        'value': 'Comidas & Bebidas / Outras Bebidas Alcoólicas',
        'label': 'Comidas & Bebidas / Outras Bebidas Alcoólicas'
      }, {
        'value': 'Esportes / Artigos Esportivos',
        'label': 'Esportes / Artigos Esportivos'
      }, {
        'value': 'Mídia / Mídia Online',
        'label': 'Mídia / Mídia On-line'
      }, {
        'value': 'Artes & Entretenimento / Filmes / Personagem',
        'label': 'Artes & Entretenimento / Filmes / Personagem'
      }, {
        'value': 'Telecomunicação',
        'label': 'Telecomunicação'
      }, {
        'value': 'Shopping / Compras / Atacado / Varejo',
        'label': 'Shopping / Compras / Atacado / Varejo'
      }, {
        'value': 'Viagens',
        'label': 'Viagens'
      }, {
        'value': 'Artes & Entretenimento / Indústria Cinematográfica',
        'label': 'Artes & Entretenimento / Indústria Cinematográfica'
      }, {
        'value': 'Mídia / Social',
        'label': 'Mídia / Social'
      }, {
        'value': 'Saúde & Fitness / Tratamento / Medicina Alternativa',
        'label': 'Saúde & Fitness / Tratamento / Medicina Alternativa'
      }, {
        'value': 'Família & Paternidade / Mãe / Pai',
        'label': 'Família & Paternidade / Mãe / Pai'
      }, {
        'value': 'Direito, Governo e Política / Direitos Humanos',
        'label': 'Direito, Governo e Política / Direitos Humanos'
      }, {
        'value': 'Religião & Espiritualidade',
        'label': 'Religião & Espiritualidade'
      }, {
        'value': 'Comidas & Bebidas / Gastronomia / Culinária',
        'label': 'Comidas & Bebidas / Gastronomia / Culinária'
      }, {
        'value': 'Casa & Jardim / Decoração de Interiores',
        'label': 'Casa & Jardim / Decoração de Interiores'
      }, {
        'value': 'Direito, Governo e Política / Crime / Justiça',
        'label': 'Direito, Governo e Política / Crime / Justiça'
      }, {
        'value': 'Ciências & Meio Ambiente / Ciências / Biotecnologia',
        'label': 'Ciências & Meio Ambiente / Ciências / Biotecnologia'
      }, {
        'value': 'Comunicação, Criatividade & Inovação',
        'label': 'Comunicação, Criatividade & Inovação'
      }, {
        'value': 'Finanças / Negócios / Bens Industriais / Matéria Prima',
        'label': 'Finanças / Negócios / Bens Industriais / Matéria Prima'
      }, {
        'value': 'Viagens / Hospedagem',
        'label': 'Viagens / Hospedagem'
      }, {
        'value': 'Shopping / Compras',
        'label': 'Shopping / Compras'
      }, {
        'value': 'Finanças / Negócios / Empresa de Conteúdo',
        'label': 'Finanças / Negócios / Empresa de Conteúdo'
      }, {
        'value': 'Sociedade / Costumes e Tradição',
        'label': 'Sociedade / Costumes e Tradição'
      }, {
        'value': 'Ciências & Meio Ambiente',
        'label': 'Ciências & Meio Ambiente'
      }, {
        'value': 'Mídia / Mensagens Instantâneas / Chat',
        'label': 'Mídia / Mensagens Instantâneas / Chat'
      }, {
        'value': 'Tecnologia & Computação / Comércio Eletrônico',
        'label': 'Tecnologia & Computação / Comércio Eletrônico'
      }, {
        'value': 'Sociedade / Serviços',
        'label': 'Sociedade / Serviços'
      }, {
        'value': 'Artes & Entretenimento / Celebridade / Fofoca',
        'label': 'Artes & Entretenimento / Celebridade / Fofoca'
      }, {
        'value': 'Ciências & Meio Ambiente / Biologia',
        'label': 'Ciências & Meio Ambiente / Biologia'
      }, {
        'value': 'Ciências & Meio Ambiente / Preservação / Sustentabilidade',
        'label': 'Ciências & Meio Ambiente / Preservação / Sustentabilidade'
      }, {
        'value': 'Animais de Estimação',
        'label': 'Animais de Estimação'
      }, {
        'value': 'Saúde & Fitness / Saúde e Segurança no Trabalho',
        'label': 'Saúde & Fitness / Saúde e Segurança no Trabalho'
      }, {
        'value': 'Tecnologia & Computação / Software Antivírus',
        'label': 'Tecnologia & Computação / Software Antivírus'
      }, {
        'value': 'Viagens / África',
        'label': 'Viagens / África'
      }, {
        'value': 'Ciências & Meio Ambiente / Resíduos e Poluição',
        'label': 'Ciências & Meio Ambiente / Resíduos e Poluição'
      }, {
        'value': 'Esportes / Hoquei sobre Grama',
        'label': 'Esportes / Hoquei sobre Grama'
      }, {
        'value': 'Viagens / Cruzeiros',
        'label': 'Viagens / Cruzeiros'
      }, {
        'value': 'Esportes / Esportes Aquáticos',
        'label': 'Esportes / Esportes Aquáticos'
      }, {
        'value': 'Tecnologia & Computação / Câmeras Digitais e Filmadoras',
        'label': 'Tecnologia & Computação / Câmeras Digitais e Filmadoras'
      }, {
        'value': 'Banco',
        'label': 'Banco'
      }, {
        'value': 'Educação / Vida Universitária',
        'label': 'Educação / Vida Universitária'
      }, {
        'value': 'Hobbies & Interesses / Jogos de Tabuleiro / Enigma',
        'label': 'Hobbies & Interesses / Jogos de Tabuleiro / Enigma'
      }, {
        'value': 'Comidas & Bebidas / Receitas',
        'label': 'Comidas & Bebidas / Receitas'
      }, {
        'value': 'Tecnologia & Computação / Banco de Dados',
        'label': 'Tecnologia & Computação / Banco de Dados'
      }, {
        'value': 'Automotivo / Sedan',
        'label': 'Automotivo / Sedan'
      }, {
        'value': 'Economia',
        'label': 'Economia'
      }, {
        'value': 'Saúde & Fitness / Geriatria',
        'label': 'Saúde & Fitness / Geriatria'
      }, {
        'value': 'Contabilidade e Auditoria',
        'label': 'Contabilidade e Auditoria'
      }, {
        'value': 'Viagens / Lua de Mel',
        'label': 'Viagens / Lua de Mel'
      }, {
        'value': 'Tecnologia & Computação / Celulares',
        'label': 'Tecnologia & Computação / Celulares'
      }, {
        'value': 'Moda & Estilo / Jóia / Acessórios',
        'label': 'Moda & Estilo / Jóia / Acessórios'
      }, {
        'value': 'Viagens / Restaurantes e Cafeterias',
        'label': 'Viagens / Restaurantes e Cafeterias'
      }, {
        'value': 'Casa & Jardim / Mobília',
        'label': 'Casa & Jardim / Mobília'
      }, {
        'value': 'Saúde & Fitness / Psicologia / Psiquiatria',
        'label': 'Saúde & Fitness / Psicologia / Psiquiatria'
      }, {
        'value': 'Comidas & Bebidas / Vinho',
        'label': 'Comidas & Bebidas / Vinho'
      }, {
        'value': 'Viagens / Reservas de Viagens',
        'label': 'Viagens / Reservas de Viagens'
      }, {
        'value': 'Sociedade / Etnia Específica',
        'label': 'Sociedade / Etnia Específica'
      }, {
        'value': 'Automotivo / Auto Peças / Auto Reparação',
        'label': 'Automotivo / Auto Peças / Auto Reparação'
      }, {
        'value': 'Sociedade / Adolescência',
        'label': 'Sociedade / Adolescência'
      }, {
        'value': 'Automotivo / Hatchback',
        'label': 'Automotivo / Hatchback'
      }, {
        'value': 'Tecnologia & Computação / Redes de Computadores',
        'label': 'Tecnologia & Computação / Redes de Computadores'
      }, {
        'value': 'Conteúdo Impróprio / Pornografia',
        'label': 'Conteúdo Impróprio / Pornografia'
      }, {
        'value': 'Artes & Entretenimento / Arte Fina',
        'label': 'Artes & Entretenimento / Arte Fina'
      }, {
        'value': 'Esportes / Esportes Radicais',
        'label': 'Esportes / Esportes Radicais'
      }, {
        'value': 'Esportes / Natação',
        'label': 'Esportes / Natação'
      }, {
        'value': 'Esportes / Golfe',
        'label': 'Esportes / Golfe'
      }, {
        'value': 'Carreiras / Bolsas de Estudo',
        'label': 'Carreiras / Bolsas de Estudo'
      }, {
        'value': 'Conteúdo Impróprio',
        'label': 'Conteúdo Impróprio'
      }, {
        'value': 'Notícias / Notícias Independentes / Nova Mídia',
        'label': 'Notícias / Notícias Independentes / Nova Mídia'
      }, {
        'value': 'Conteúdo Impróprio / Conteúdo Profano',
        'label': 'Conteúdo Impróprio / Conteúdo Profano'
      }, {
        'value': 'Tecnologia & Computação / Sistema Operacional',
        'label': 'Tecnologia & Computação / Sistema Operacional'
      }, {
        'value': 'Tecnologia & Computação / Aplicativos',
        'label': 'Tecnologia & Computação / Aplicativos'
      }, {
        'value': 'Religião & Espiritualidade / Evangelicalismo',
        'label': 'Religião & Espiritualidade / Evangelicalismo'
      }, {
        'value': 'Casa & Jardim / Remodelação e Construção',
        'label': 'Casa & Jardim / Remodelação e Construção'
      }, {
        'value': 'Ciências & Meio Ambiente / Química / Física',
        'label': 'Ciências & Meio Ambiente / Química / Física'
      }, {
        'value': 'Educação / Aprendizagem de Línguas',
        'label': 'Educação / Aprendizagem de Línguas'
      }, {
        'value': 'Esportes / Lutas / Artes Marciais',
        'label': 'Esportes / Lutas / Artes Marciais'
      }, {
        'value': 'Esportes / Badminton',
        'label': 'Esportes / Badminton'
      }, {
        'value': 'Animais de Estimação / Animais Selvagens',
        'label': 'Animais de Estimação / Animais Selvagens'
      }, {
        'value': 'Tecnologia & Computação / Web Design',
        'label': 'Tecnologia & Computação / Web Design'
      }, {
        'value': 'Notícias / Noticias Financeiras',
        'label': 'Notícias / Noticias Financeiras'
      }, {
        'value': 'Esportes / Voleibol',
        'label': 'Esportes / Voleibol'
      }, {
        'value': 'Saúde & Fitness / Pânico / Transtornos de Ansiedade',
        'label': 'Saúde & Fitness / Pânico / Transtornos de Ansiedade'
      }, {
        'value': 'Automotivo / Industria Automobilística',
        'label': 'Automotivo / Industria Automobilística'
      }, {
        'value': 'Educação / Dicas de Estudo',
        'label': 'Educação / Dicas de Estudo'
      }, {
        'value': 'Artes & Entretenimento / Artes Visuais',
        'label': 'Artes & Entretenimento / Artes Visuais'
      }, {
        'value': 'Saúde & Fitness / Bem Estar',
        'label': 'Saúde & Fitness / Bem Estar'
      }, {
        'value': 'Saúde & Fitness / Ortopedia',
        'label': 'Saúde & Fitness / Ortopedia'
      }, {
        'value': 'Saúde & Fitness / Diabetes',
        'label': 'Saúde & Fitness / Diabetes'
      }, {
        'value': 'Imobiliário / Apartamentos / Casas',
        'label': 'Imobiliário / Apartamentos / Casas'
      }, {
        'value': 'Automotivo / MiniVan',
        'label': 'Automotivo / MiniVan'
      }, {
        'value': 'Tecnologia & Computação / Pesquisa / Busca',
        'label': 'Tecnologia & Computação / Pesquisa / Busca'
      }, {
        'value': 'Saúde & Fitness / Doença Cardíaca',
        'label': 'Saúde & Fitness / Doença Cardíaca'
      }, {
        'value': 'Tecnologia & Computação / Programação',
        'label': 'Tecnologia & Computação / Programação'
      }, {
        'value': 'Hobbies & Interesses / Sci-Fi & Fantasia',
        'label': 'Hobbies & Interesses / Sci-Fi & Fantasia'
      }, {
        'value': 'Religião & Espiritualidade / Catolicismo',
        'label': 'Religião & Espiritualidade / Catolicismo'
      }, {
        'value': 'Automotivo / Cultura Automobilística',
        'label': 'Automotivo / Cultura Automobilística'
      }, {
        'value': 'Automotivo / Crossover',
        'label': 'Automotivo / Crossover'
      }, {
        'value': 'Tecnologia & Computação / Suporte Técnico',
        'label': 'Tecnologia & Computação / Suporte Técnico'
      }, {
        'value': 'Religião & Espiritualidade / Santos dos Últimos Dias',
        'label': 'Religião & Espiritualidade / Santos dos Últimos Dias'
      }, {
        'value': 'Conteúdo Impróprio / Conteúdo de Ódio / Violência',
        'label': 'Conteúdo Impróprio / Conteúdo de Ódio / Violência'
      }, {
        'value': 'Planejamento Tributário',
        'label': 'Planejamento Tributário'
      }, {
        'value': 'Mídia / Streaming Música / Vídeo',
        'label': 'Mídia / Streaming Música / Vídeo'
      }, {
        'value': 'Saúde & Fitness / Profissionais da Saúde',
        'label': 'Saúde & Fitness / Profissionais da Saúde'
      }, {
        'value': 'Educação / Educação de 7 a 12 anos',
        'label': 'Educação / Educação de 7 a 12 anos'
      }, {
        'value': 'Automotivo / Motocicleta',
        'label': 'Automotivo / Motocicleta'
      }, {
        'value': 'Esportes / Power & Motos',
        'label': 'Esportes / Power & Motos'
      }, {
        'value': 'Religião & Espiritualidade / Testemunha de Jeová',
        'label': 'Religião & Espiritualidade / Testemunha de Jeová'
      }, {
        'value': 'Esportes / Basquete',
        'label': 'Esportes / Basquete'
      }, {
        'value': 'Viagens / EUA',
        'label': 'Viagens / EUA'
      }, {
        'value': 'Religião & Espiritualidade / Espiritismo',
        'label': 'Religião & Espiritualidade / Espiritismo'
      }, {
        'value': 'Saúde & Fitness / Epidemia',
        'label': 'Saúde & Fitness / Epidemia'
      }, {
        'value': 'Viagens / Agência',
        'label': 'Viagens / Agência'
      }, {
        'value': 'Automotivo / Coupe',
        'label': 'Automotivo / Coupe'
      }, {
        'value': 'Automotivo / Caminhão / Ônibus / Vagão',
        'label': 'Automotivo / Caminhão / Ônibus / Vagão'
      }, {
        'value': 'Esportes / Atletismo',
        'label': 'Esportes / Atletismo'
      }, {
        'value': 'Direito, Governo e Política / Imigração',
        'label': 'Direito, Governo e Política / Imigração'
      }, {
        'value': 'Religião & Espiritualidade / Judaísmo',
        'label': 'Religião & Espiritualidade / Judaísmo'
      }, {
        'value': 'Sociedade / Casamento / Divórcio',
        'label': 'Sociedade / Casamento / Divórcio'
      }, {
        'value': 'Tecnologia & Computação / Console de Jogos',
        'label': 'Tecnologia & Computação / Console de Jogos'
      }, {
        'value': 'Animais de Estimação / Aquários',
        'label': 'Animais de Estimação / Aquários'
      }, {
        'value': 'Automotivo / Transporte',
        'label': 'Automotivo / Transporte'
      }, {
        'value': 'Saúde & Fitness / Surdez / Cegueira',
        'label': 'Saúde & Fitness / Surdez / Cegueira'
      }, {
        'value': 'Esportes / Nado Sincronizado',
        'label': 'Esportes / Nado Sincronizado'
      }, {
        'value': 'Família & Paternidade / Internet Familiar',
        'label': 'Família & Paternidade / Internet Familiar'
      }, {
        'value': 'Shopping / Compras / Pedido e Entrega',
        'label': 'Shopping / Compras / Pedido e Entrega'
      }, {
        'value': 'Automotivo / Veículo Off-Road',
        'label': 'Automotivo / Veículo Off-Road'
      }, {
        'value': 'Automotivo / Carros Clássicos',
        'label': 'Automotivo / Carros Clássicos'
      }, {
        'value': 'Hobbies & Interesses / Brinquedos',
        'label': 'Hobbies & Interesses / Brinquedos'
      }, {
        'value': 'Shopping / Compras / Coupons / Promoção',
        'label': 'Shopping / Compras / Coupons / Promoção'
      }, {
        'value': 'Hobbies & Interesses / Jogos de Cartas',
        'label': 'Hobbies & Interesses / Jogos de Cartas'
      }, {
        'value': 'Viagens / Japão',
        'label': 'Viagens / Japão'
      }, {
        'value': 'Imobiliário / Engenharia / Arquitetura',
        'label': 'Imobiliário / Engenharia / Arquitetura'
      }, {
        'value': 'Notícias / Notícias Nacionais',
        'label': 'Notícias / Notícias Nacionais'
      }, {
        'value': 'Viagens / Atrativos',
        'label': 'Viagens / Atrativos'
      }, {
        'value': 'Notícias / Notícias Internacionais',
        'label': 'Notícias / Notícias Internacionais'
      }, {
        'value': 'Sociedade / Velhice',
        'label': 'Sociedade / Velhice'
      }, {
        'value': 'Esportes / Handebol',
        'label': 'Esportes / Handebol'
      }, {
        'value': 'Comidas & Bebidas / Café / Chá',
        'label': 'Comidas & Bebidas / Café / Chá'
      }, {
        'value': 'Saúde & Fitness / Epilepsia',
        'label': 'Saúde & Fitness / Epilepsia'
      }, {
        'value': 'Educação / Educação para Adultos',
        'label': 'Educação / Educação para Adultos'
      }, {
        'value': 'Família & Paternidade',
        'label': 'Família & Paternidade'
      }, {
        'value': 'Esportes / Fisiculturismo',
        'label': 'Esportes / Fisiculturismo'
      }, {
        'value': 'Saúde & Fitness / Transtorno Bipolar',
        'label': 'Saúde & Fitness / Transtorno Bipolar'
      }, {
        'value': 'Mídia / Fórum',
        'label': 'Mídia / Fórum'
      }, {
        'value': 'Saúde & Fitness / Infertilidade',
        'label': 'Saúde & Fitness / Infertilidade'
      }, {
        'value': 'Esportes / Esgrima',
        'label': 'Esportes / Esgrima'
      }, {
        'value': 'Hobbies & Interesses / Vídeos / Gravação Caseira',
        'label': 'Hobbies & Interesses / Vídeos / Gravação Caseira'
      }, {
        'value': 'Notícias / Notícias Locais',
        'label': 'Notícias / Notícias Locais'
      }, {
        'value': 'Saúde & Fitness / Exercício Físico',
        'label': 'Saúde & Fitness / Exercício Físico'
      }, {
        'value': 'Religião & Espiritualidade / Islamismo',
        'label': 'Religião & Espiritualidade / Islamismo'
      }, {
        'value': 'Serviços Financeiros e de Negócios',
        'label': 'Serviços Financeiros e de Negócios'
      }, {
        'value': 'Esportes / Ciclismo',
        'label': 'Esportes / Ciclismo'
      }, {
        'value': 'Viagens / Canadá',
        'label': 'Viagens / Canadá'
      }, {
        'value': 'Educação / Pós-graduação',
        'label': 'Educação / Pós-graduação'
      }, {
        'value': 'Investidores e Patentes',
        'label': 'Investidores e Patentes'
      }, {
        'value': 'Sociedade / Relacionamento',
        'label': 'Sociedade / Relacionamento'
      }, {
        'value': 'Viagens / Reino Unido',
        'label': 'Viagens / Reino Unido'
      }, {
        'value': 'Conteúdo Impróprio / Incentivação',
        'label': 'Conteúdo Impróprio / Incentivação'
      }, {
        'value': 'Artes & Entretenimento / Humor',
        'label': 'Artes & Entretenimento / Humor'
      }, {
        'value': 'Saúde & Fitness / Parar de Fumar',
        'label': 'Saúde & Fitness / Parar de Fumar'
      }, {
        'value': 'Hobbies & Interesses / Coleção',
        'label': 'Hobbies & Interesses / Coleção'
      }, {
        'value': 'Saúde & Fitness / Depressão',
        'label': 'Saúde & Fitness / Depressão'
      }, {
        'value': 'Saúde & Fitness / Ginecologia / Urologia',
        'label': 'Saúde & Fitness / Ginecologia / Urologia'
      }, {
        'value': 'Ciências & Meio Ambiente / Clima / Alterações Climáticas',
        'label': 'Ciências & Meio Ambiente / Clima / Alterações Climáticas'
      }, {
        'value': 'Tecnologia & Computação / Games',
        'label': 'Tecnologia & Computação / Games'
      }, {
        'value': 'Direito, Governo e Política / Leis / Legislação',
        'label': 'Direito, Governo e Política / Leis / Legislação'
      }, {
        'value': 'Saúde & Fitness / Dores de Cabeça / Enxaqueca',
        'label': 'Saúde & Fitness / Dores de Cabeça / Enxaqueca'
      }, {
        'value': 'Automotivo / Conversível',
        'label': 'Automotivo / Conversível'
      }, {
        'value': 'Religião & Espiritualidade / Tradições Esotéricas / Indígenas',
        'label': 'Religião & Espiritualidade / Tradições Esotéricas / Indígenas'
      }, {
        'value': 'Automotivo / Veículo Elétrico',
        'label': 'Automotivo / Veículo Elétrico'
      }, {
        'value': 'Viagens / Grécia',
        'label': 'Viagens / Grécia'
      }, {
        'value': 'Sociedade / Eleições',
        'label': 'Sociedade / Eleições'
      }, {
        'value': 'Saúde & Fitness / Doenças Transmissíveis',
        'label': 'Saúde & Fitness / Doenças Transmissíveis'
      }, {
        'value': 'Tecnologia & Computação / Segurança de Rede',
        'label': 'Tecnologia & Computação / Segurança de Rede'
      }, {
        'value': 'Automotivo / Pickup',
        'label': 'Automotivo / Pickup'
      }, {
        'value': 'Conteúdo Ilegal / Violação de Direitos Autorais',
        'label': 'Conteúdo Ilegal / Violação de Direitos Autorais'
      }, {
        'value': 'Comidas & Bebidas / Alimentos',
        'label': 'Comidas & Bebidas / Alimentos'
      }, {
        'value': 'Hobbies & Interesses / Desenho / Esboço',
        'label': 'Hobbies & Interesses / Desenho / Esboço'
      }, {
        'value': 'Ciências & Meio Ambiente / Matemática',
        'label': 'Ciências & Meio Ambiente / Matemática'
      }, {
        'value': 'Viagens / Itália',
        'label': 'Viagens / Itália'
      }, {
        'value': 'Automotivo / Diesel',
        'label': 'Automotivo / Diesel'
      }, {
        'value': 'Hobbies & Interesses / Turismo e Lazer',
        'label': 'Hobbies & Interesses / Turismo e Lazer'
      }, {
        'value': 'Sociedade / Consumo',
        'label': 'Sociedade / Consumo'
      }, {
        'value': 'Educação / História da Arte',
        'label': 'Educação / História da Arte'
      }, {
        'value': 'Ciências & Meio Ambiente / Astrologia',
        'label': 'Ciências & Meio Ambiente / Astrologia'
      }, {
        'value': 'Animais de Estimação / Aves / Pássaros',
        'label': 'Animais de Estimação / Aves / Pássaros'
      }, {
        'value': 'Automotivo / Compra / Venda de Carros',
        'label': 'Automotivo / Compra / Venda de Carros'
      }, {
        'value': 'Educação / Educação até 6 anos',
        'label': 'Educação / Educação até 6 anos'
      }, {
        'value': 'Ciências & Meio Ambiente / Geografia / Geologia',
        'label': 'Ciências & Meio Ambiente / Geografia / Geologia'
      }, {
        'value': 'Tecnologia & Computação / Streaming',
        'label': 'Tecnologia & Computação / Streaming'
      }, {
        'value': 'Conteúdo Ilegal / Spyware / Malware',
        'label': 'Conteúdo Ilegal / Spyware / Malware'
      }, {
        'value': 'Direito, Governo e Política',
        'label': 'Direito, Governo e Política'
      }, {
        'value': 'Sociedade / Homossexualidade',
        'label': 'Sociedade / Homossexualidade'
      }, {
        'value': 'Automotivo / Luxo',
        'label': 'Automotivo / Luxo'
      }, {
        'value': 'Ciências & Meio Ambiente / Fenômenos Paranormais',
        'label': 'Ciências & Meio Ambiente / Fenômenos Paranormais'
      }, {
        'value': 'Hobbies & Interesses / Fotografia',
        'label': 'Hobbies & Interesses / Fotografia'
      }, {
        'value': 'Carreiras / Planejamento de Carreira',
        'label': 'Carreiras / Planejamento de Carreira'
      }, {
        'value': 'Viagens / Centro Cultural',
        'label': 'Viagens / Centro Cultural'
      }, {
        'value': 'Comidas & Bebidas / Saúde / Comida de Baixa Caloria',
        'label': 'Comidas & Bebidas / Saúde / Comida de Baixa Caloria'
      }, {
        'value': 'Viagens / Parques Nacionais / temáticos',
        'label': 'Viagens / Parques Nacionais / temáticos'
      }, {
        'value': 'Esportes / Tênis',
        'label': 'Esportes / Tênis'
      }, {
        'value': 'Hobbies & Interesses / Pintura',
        'label': 'Hobbies & Interesses / Pintura'
      }, {
        'value': 'Animais de Estimação / Medicina Veterinária',
        'label': 'Animais de Estimação / Medicina Veterinária'
      }, {
        'value': 'Casa & Jardim / Jardinagem / Paisagismo',
        'label': 'Casa & Jardim / Jardinagem / Paisagismo'
      }, {
        'value': 'Automotivo / Híbrido',
        'label': 'Automotivo / Híbrido'
      }, {
        'value': 'Saúde & Fitness / AIDS / HIV',
        'label': 'Saúde & Fitness / AIDS / HIV'
      }, {
        'value': 'Conteúdo Ilegal / Software Pirata',
        'label': 'Conteúdo Ilegal / Software Pirata'
      }, {
        'value': 'Hobbies & Interesses / Magica e Ilusão',
        'label': 'Hobbies & Interesses / Magica e Ilusão'
      }, {
        'value': 'Religião & Espiritualidade / Cristianismo',
        'label': 'Religião & Espiritualidade / Cristianismo'
      }, {
        'value': 'Saúde & Fitness / Nutrição',
        'label': 'Saúde & Fitness / Nutrição'
      }, {
        'value': 'Viagens / Shopping',
        'label': 'Viagens / Shopping'
      }, {
        'value': 'Educação / Educação Especial',
        'label': 'Educação / Educação Especial'
      }, {
        'value': 'Saúde & Fitness / Alergias',
        'label': 'Saúde & Fitness / Alergias'
      }, {
        'value': 'Carreiras / Procura / Feiras de Emprego',
        'label': 'Carreiras / Procura / Feiras de Emprego'
      }, {
        'value': 'Saúde & Fitness / Pediatria',
        'label': 'Saúde & Fitness / Pediatria'
      }, {
        'value': 'Comunicação, Criatividade & Inovação / Publicidade',
        'label': 'Comunicação, Criatividade & Inovação / Publicidade'
      }, {
        'value': 'Comunicação, Criatividade & Inovação / Relações Públicas',
        'label': 'Comunicação, Criatividade & Inovação / Relações Públicas'
      }, {
        'value': 'Esportes / Tênis de Mesa / Ping-Pong',
        'label': 'Esportes / Tênis de Mesa / Ping-Pong'
      }, {
        'value': 'Animais de Estimação / Gatos / Cães',
        'label': 'Animais de Estimação / Gatos / Cães'
      }, {
        'value': 'Esportes / Caça / Tiro',
        'label': 'Esportes / Caça / Tiro'
      }, {
        'value': 'Hobbies & Interesses / Fabricação de Artesanatos  / Bordado',
        'label': 'Hobbies & Interesses / Fabricação de Artesanatos  / Bordado'
      }, {
        'value': 'Viagens / Spa',
        'label': 'Viagens / Spa'
      }, {
        'value': 'Religião & Espiritualidade / Religiões Alternativas',
        'label': 'Religião & Espiritualidade / Religiões Alternativas'
      }, {
        'value': 'Esportes / Rugby',
        'label': 'Esportes / Rugby'
      }, {
        'value': 'Saúde & Fitness / Doenças / Síndromes',
        'label': 'Saúde & Fitness / Doenças / Síndromes'
      }, {
        'value': 'Religião & Espiritualidade / Budismo',
        'label': 'Religião & Espiritualidade / Budismo'
      }, {
        'value': 'Esportes / Paintball',
        'label': 'Esportes / Paintball'
      }, {
        'value': 'Religião & Espiritualidade / Hinduismo',
        'label': 'Religião & Espiritualidade / Hinduismo'
      }, {
        'value': 'Saúde & Fitness / Fisioterapia',
        'label': 'Saúde & Fitness / Fisioterapia'
      }, {
        'value': 'Esportes / Levantamento de Peso',
        'label': 'Esportes / Levantamento de Peso'
      }, {
        'value': 'Saúde & Fitness / Higiene / Cuidados de Saúde',
        'label': 'Saúde & Fitness / Higiene / Cuidados de Saúde'
      }, {
        'value': 'Família & Paternidade / Gravidez',
        'label': 'Família & Paternidade / Gravidez'
      }, {
        'value': 'Saúde & Fitness / Perda de Peso',
        'label': 'Saúde & Fitness / Perda de Peso'
      }, {
        'value': 'Família & Paternidade / Adoção',
        'label': 'Família & Paternidade / Adoção'
      }, {
        'value': 'Saúde & Fitness / Colesterol',
        'label': 'Saúde & Fitness / Colesterol'
      }, {
        'value': 'Saúde & Fitness / Dermatologia',
        'label': 'Saúde & Fitness / Dermatologia'
      }, {
        'value': 'Saúde & Fitness / Autismo / PDD',
        'label': 'Saúde & Fitness / Autismo / PDD'
      }, {
        'value': 'Viagens / Caribe',
        'label': 'Viagens / Caribe'
      }, {
        'value': 'Viagens / Europa',
        'label': 'Viagens / Europa'
      }, {
        'value': 'Automotivo / Veículos de Desempenho',
        'label': 'Automotivo / Veículos de Desempenho'
      }, {
        'value': 'Conteúdo Ilegal',
        'label': 'Conteúdo Ilegal'
      }, {
        'value': 'Família & Paternidade / Creche / Pré-escolar',
        'label': 'Família & Paternidade / Creche / Pré-escolar'
      }, {
        'value': 'Saúde & Fitness / Doença de Tiróide',
        'label': 'Saúde & Fitness / Doença de Tiróide'
      }, {
        'value': 'Viagens / Viagem de Negócios',
        'label': 'Viagens / Viagem de Negócios'
      }, {
        'value': 'Ciências & Meio Ambiente / Energia e Recursos',
        'label': 'Ciências & Meio Ambiente / Energia e Recursos'
      }, {
        'value': 'Comidas & Bebidas / Cocktails / Cerveja',
        'label': 'Comidas & Bebidas / Cocktails / Cerveja'
      }, {
        'value': 'Hobbies & Interesses / Charutos',
        'label': 'Hobbies & Interesses / Charutos'
      }, {
        'value': 'Tecnologia & Computação / Animação',
        'label': 'Tecnologia & Computação / Animação'
      }, {
        'value': 'Viagens / França',
        'label': 'Viagens / França'
      }, {
        'value': 'Educação / Aprendizado a Distância',
        'label': 'Educação / Aprendizado a Distância'
      }, {
        'value': 'Imobiliário',
        'label': 'Imobiliário'
      }, {
        'value': 'Esportes / Hipismo',
        'label': 'Esportes / Hipismo'
      }, {
        'value': 'Esportes / Futebol',
        'label': 'Esportes / Futebol'
      }, {
        'value': 'Comidas & Bebidas / Pimentas e Condimentos',
        'label': 'Comidas & Bebidas / Pimentas e Condimentos'
      }, {
        'value': 'Saúde & Fitness / Odontologia',
        'label': 'Saúde & Fitness / Odontologia'
      }, {
        'value': 'Casa & Jardim / Habitação / Acomodação',
        'label': 'Casa & Jardim / Habitação / Acomodação'
      }, {
        'value': 'Religião & Espiritualidade / Ateísmo / Agnosticismo',
        'label': 'Religião & Espiritualidade / Ateísmo / Agnosticismo'
      }, {
        'value': 'Esportes / Pesca de Água Doce / Água Salgada',
        'label': 'Esportes / Pesca de Água Doce / Água Salgada'
      }, {
        'value': 'Imobiliário / Compra / Venda de imóveis',
        'label': 'Imobiliário / Compra / Venda de imóveis'
      }, {
        'value': 'Saúde & Fitness / Câncer',
        'label': 'Saúde & Fitness / Câncer'
      }, {
        'value': 'Viagens / Austrália e Nova Zelândia',
        'label': 'Viagens / Austrália e Nova Zelândia'
      }, {
        'value': 'Moda & Estilo / Vestuário / Calçados',
        'label': 'Moda & Estilo / Vestuário / Calçados'
      }, {
        'value': 'Esportes / Pólo Aquático',
        'label': 'Esportes / Pólo Aquático'
      }, {
        'value': 'Religião & Espiritualidade / Umbanda e Candomblé',
        'label': 'Religião & Espiritualidade / Umbanda e Candomblé'
      }, {
        'value': 'Artes & Entretenimento / Curiosidades / Atualidades',
        'label': 'Artes & Entretenimento / Curiosidades / Atualidades'
      }, {
        'value': 'Crédito / Débito e Empréstimos',
        'label': 'Crédito / Débito e Empréstimos'
      }]
    },
    'domain': 'string'
  }, {
    'Termos': {
      data: [{
        value: '0',
        label: 'Todos'
      }],
      domain: 'invisible'
    }
  }, {
    'Canais': {
      data: [{
        value: 'instagram',
        label: 'Instagram'
      }, {
        value: 'instagramcomments',
        label: 'Comentários no Instagram',
        tags: ['comment']
      }, {
        value: 'linkedincomments',
        label: 'Comentários no LinkedIn',
        tags: ['comment']
      }, {
        value: 'facebook',
        label: 'Facebook'
      }, {
        value: 'facebookgroups',
        label: 'Grupos no Facebook'
      }, {
        value: 'facebookcomments',
        label: 'Comentários no Facebook',
        tags: ['comment']
      }, {
        value: 'twitter',
        label: 'Twitter'
      }, {
        value: 'tumblr',
        label: 'Tumblr'
      }, {
        value: 'googleplus',
        label: 'Google+'
      }, {
        value: 'googlemybusinessreviews',
        label: 'Avaliações no Google Meu Negócio'
      }, {
        value: 'news',
        label: 'Portais'
      }, {
        value: 'blogs',
        label: 'Blogs'
      }, {
        value: 'youtube',
        label: 'YouTube'
      }, {
        value: 'youtubecomments',
        label: 'Comentários no YouTube',
        tags: ['comment']
      }, {
        value: 'foruns',
        label: 'Fóruns'
      }, {
        value: 'comentariosemartigos',
        label: 'Comentários em Artigos',
        tags: ['comment']
      }, {
        value: 'linkedin',
        label: 'LinkedIn'
      }, {
        value: 'reclameaqui',
        label: 'Reclame Aqui'
      }, {
        value: 'tv',
        label: 'TV'
      }, {
        value: 'radio',
        label: 'Rádio'
      }, {
        value: 'impressas',
        label: 'Impressas'
      }, {
        value: 'zapier',
        label: 'Zapier'
      }, {
        value: 'googleplay',
        label: 'Avaliações no Google Play'
      }, {
        value: 'ImportedData',
        label: 'Dados Importados'
      }, {
        value: 'Bluesky',
        label: 'Bluesky'
      }],
      domain: 'string'
    }
  }, {
    'Checado': {
      data: [{
        value: '[]',
        label: 'Todos'
      }, {
        value: 'true',
        label: 'Sim'
      }, {
        value: 'false',
        label: 'Não'
      }],
      domain: 'invisible'
    }
  }, {
    'Publicações anotadas': {
      data: [{
        value: '[]',
        label: 'Todos'
      }, {
        value: 'true',
        label: 'Sim'
      }, {
        value: 'false',
        label: 'Não'
      }],
      domain: 'invisible'
    }
  }, {
    'Publicações removidas': {
      data: [{
        value: 'false',
        label: 'Todos'
      }, {
        value: 'true',
        label: 'Sim'
      }],
      domain: 'invisible'
    }
  }, {
    'Sentimento': {
      data: [{
        value: 0,
        label: 'Não Classificados'
      }, {
        value: 1,
        label: 'Positivo'
      }, {
        value: 2,
        label: 'Negativo'
      }, {
        value: 3,
        label: 'Neutro'
      }, {
        value: 4,
        label: 'Muito Negativo'
      }, {
        value: 5,
        label: 'Muito Positivo'
      }],
      domain: 'int'
    }
  }, {
    'Gênero': {
      data: [{
        value: 0,
        label: 'Não Classificados'
      }, {
        value: 1,
        label: 'Homem'
      }, {
        value: 2,
        label: 'Mulher'
      }, {
        value: 3,
        label: 'Organização'
      }, {
        value: 4,
        label: 'LGBT'
      }, {
        value: 5,
        label: 'Lugar'
      }, {
        value: 6,
        label: 'Evento'
      }, {
        value: 7,
        label: 'Marca'
      }, {
        value: 8,
        label: 'Veículo'
      }],
      domain: 'int'
    }
  }, {
    'Locais': {
      data: [{
        value: 'Mato Grosso',
        label: 'Mato Grosso'
      }, {
        value: 'Maranhão',
        label: 'Maranhão'
      }, {
        value: 'Santa Catarina',
        label: 'Santa Catarina'
      }, {
        value: 'Piauí',
        label: 'Piauí'
      }, {
        value: 'Sergipe',
        label: 'Sergipe'
      }, {
        value: 'Ceará',
        label: 'Ceará'
      }, {
        value: 'Pará',
        label: 'Pará'
      }, {
        value: 'Distrito Federal',
        label: 'Distrito Federal'
      }, {
        value: 'Pernambuco',
        label: 'Pernambuco'
      }, {
        value: 'Tocantins',
        label: 'Tocantins'
      }, {
        value: 'Mato Grosso do Sul',
        label: 'Mato Grosso do Sul'
      }, {
        value: 'Rio Grande do Sul',
        label: 'Rio Grande do Sul'
      }, {
        value: 'Goiás',
        label: 'Goiás'
      }, {
        value: 'Rio de Janeiro',
        label: 'Rio de Janeiro'
      }, {
        value: 'Rondônia',
        label: 'Rondônia'
      }, {
        value: 'Rio Grande do Norte',
        label: 'Rio Grande do Norte'
      }, {
        value: 'Amapá',
        label: 'Amapá'
      }, {
        value: 'Minas Gerais',
        label: 'Minas Gerais'
      }, {
        value: 'Bahia',
        label: 'Bahia'
      }, {
        value: 'Roraima',
        label: 'Roraima'
      }, {
        value: 'Espírito Santo',
        label: 'Espírito Santo'
      }, {
        value: 'Acre',
        label: 'Acre'
      }, {
        value: 'Paraná',
        label: 'Paraná'
      }, {
        value: 'Amazonas',
        label: 'Amazonas'
      }, {
        value: 'Paraíba',
        label: 'Paraíba'
      }, {
        value: 'São Paulo',
        label: 'São Paulo'
      }, {
        value: 'Alagoas',
        label: 'Alagoas'
      }],
      domain: 'string'
    }
  }, {
    'Tipos de publicações': {
      data: [{
        value: 'post',
        label: 'Texto'
      }, {
        value: 'image',
        label: 'Imagem'
      }, {
        value: 'video',
        label: 'Vídeo'
      }, {
        value: 'audio',
        label: 'Áudio'
      }],
      domain: 'string'
    }
  }, {
    'Dispositivos': {
      data: [{
        value: 0,
        label: 'Todos'
      }, {
        value: 1,
        label: 'Computador'
      }, {
        value: 2,
        label: 'Celular/Tablet'
      }],
      domain: 'string'
    }
  }, {
    'Tags': {
      data: [],
      domain: 'string'
    }
  }, {
    'Contendo texto': {
      data: [],
      domain: 'string'
    }
  }, {
    'Publicado na hora': {
      data: [{
        value: 0,
        label: '0h'
      }, {
        value: 1,
        label: '1h'
      }, {
        value: 2,
        label: '2h'
      }, {
        value: 3,
        label: '3h'
      }, {
        value: 4,
        label: '4h'
      }, {
        value: 5,
        label: '5h'
      }, {
        value: 6,
        label: '6h'
      }, {
        value: 7,
        label: '7h'
      }, {
        value: 8,
        label: '8h'
      }, {
        value: 9,
        label: '9h'
      }, {
        value: 10,
        label: '10h'
      }, {
        value: 11,
        label: '11h'
      }, {
        value: 12,
        label: '12h'
      }, {
        value: 13,
        label: '13h'
      }, {
        value: 14,
        label: '14h'
      }, {
        value: 15,
        label: '15h'
      }, {
        value: 16,
        label: '16h'
      }, {
        value: 17,
        label: '17h'
      }, {
        value: 18,
        label: '18h'
      }, {
        value: 19,
        label: '19h'
      }, {
        value: 20,
        label: '20h'
      }, {
        value: 21,
        label: '21h'
      }, {
        value: 22,
        label: '22h'
      }, {
        value: 23,
        label: '23h'
      }],
      domain: 'string'
    }
  }, {
    'Publicado no dia do mês': {
      data: [{
        value: 1,
        label: '1'
      }, {
        value: 2,
        label: '2'
      }, {
        value: 3,
        label: '3'
      }, {
        value: 4,
        label: '4'
      }, {
        value: 5,
        label: '5'
      }, {
        value: 6,
        label: '6'
      }, {
        value: 7,
        label: '7'
      }, {
        value: 8,
        label: '8'
      }, {
        value: 9,
        label: '9'
      }, {
        value: 10,
        label: '10'
      }, {
        value: 11,
        label: '11'
      }, {
        value: 12,
        label: '12'
      }, {
        value: 13,
        label: '13'
      }, {
        value: 14,
        label: '14'
      }, {
        value: 15,
        label: '15'
      }, {
        value: 16,
        label: '16'
      }, {
        value: 17,
        label: '17'
      }, {
        value: 18,
        label: '18'
      }, {
        value: 19,
        label: '19'
      }, {
        value: 20,
        label: '20'
      }, {
        value: 21,
        label: '21'
      }, {
        value: 22,
        label: '22'
      }, {
        value: 23,
        label: '23'
      }, {
        value: 24,
        label: '24'
      }, {
        value: 25,
        label: '25'
      }, {
        value: 26,
        label: '26'
      }, {
        value: 27,
        label: '27'
      }, {
        value: 28,
        label: '28'
      }, {
        value: 29,
        label: '29'
      }, {
        value: 30,
        label: '30'
      }, {
        value: 31,
        label: '31'
      }],
      domain: 'string'
    }
  }, {
    'Publicado no dia da semana': {
      data: [{
        value: 1,
        label: 'Domingo'
      }, {
        value: 2,
        label: 'Segunda'
      }, {
        value: 3,
        label: 'Terça'
      }, {
        value: 4,
        label: 'Quarta'
      }, {
        value: 5,
        label: 'Quinta'
      }, {
        value: 6,
        label: 'Sexta'
      }, {
        value: 7,
        label: 'Sábado'
      }],
      domain: 'string'
    }
  }, {
    'Publicado no mês': {
      data: [{
        value: 0,
        label: 'Janeiro'
      }, {
        value: 1,
        label: 'Fevereiro'
      }, {
        value: 2,
        label: 'Março'
      }, {
        value: 3,
        label: 'Abril'
      }, {
        value: 4,
        label: 'Maio'
      }, {
        value: 5,
        label: 'Junho'
      }, {
        value: 6,
        label: 'Julho'
      }, {
        value: 7,
        label: 'Agosto'
      }, {
        value: 8,
        label: 'Setembro'
      }, {
        value: 9,
        label: 'Outubro'
      }, {
        value: 10,
        label: 'Novembro'
      }, {
        value: 11,
        label: 'Dezembro'
      }],
      domain: 'string'
    }
  }, {
    'Links': {
      data: [],
      domain: 'string'
    }
  }, {
    'Buscas': {
      data: [],
      domain: 'string'
    }
  }, {
    'Hashtags': {
      data: [],
      domain: 'string'
    }
  }, {
    'Domínios': {
      data: [],
      domain: 'string'
    }
  }, {
    'Arquivo': {
      data: [],
      domain: 'string'
    }
  }, {
    'Usuários': {
      data: [],
      domain: 'string'
    }
  }, {
    'Língua': {
      data: [{
        value: 'pt',
        label: 'Português'
      }, {
        value: 'en',
        label: 'Inglês'
      }, {
        value: 'es',
        label: 'Espanhol'
      }, {
        value: 'other',
        label: 'Outros'
      }],
      domain: 'string'
    }
  }, {
    'Criticidade': {
      data: [{
        value: 0,
        label: 'Sem Criticidade'
      }, {
        value: 3,
        label: 'Alta'
      }, {
        value: 2,
        label: 'Média'
      }, {
        value: 1,
        label: 'Baixa'
      }],
      domain: 'string'
    }
  }, {
    'Status': {
      data: [{
        value: 'Ignorado',
        label: 'Ignorado'
      }, {
        value: 'Pendente',
        label: 'Pendente'
      }, {
        value: 'Aberto',
        label: 'Aberto'
      }, {
        value: 'Em Espera',
        label: 'Em Espera'
      }, {
        value: 'Respondido',
        label: 'Respondido'
      }, {
        value: 'Fechado',
        label: 'Fechado'
      }],
      domain: 'string'
    }
  }, {
    'Respondente': {
      data: [],
      domain: 'string'
    }
  }, {
    'Publicações com id': {
      data: [],
      domain: 'string'
    }
  }, {
    'Conversas com id': {
      data: [],
      domain: 'string'
    }
  }, {
    'Tipos de interação': {
      data: [{
        value: 'Interactions',
        label: 'Todas as interações'
      }, {
        value: 'Inbox',
        label: 'Inbox'
      }, {
        value: 'PostComment',
        label: 'Comentários em publicações'
      }, {
        value: 'Mention',
        label: 'Menções'
      }, {
        value: 'MentionComment',
        label: 'Comentários em menções'
      }, {
        value: 'Review',
        label: 'Avaliações'
      }, {
        value: 'ReviewComment',
        label: 'Comentários em avaliações'
      }, {
        value: 'VisitorPost',
        label: 'Publicações de visitantes'
      }, {
        value: 'VisitorComment',
        label: 'Comentários em publicações de visitantes'
      }, {
        value: 'Post',
        label: 'Publicações proprietárias'
      }, {
        value: 'Reels',
        label: 'Reels'
      }, {
        value: '',
        label: 'Todas as interaçoes'
      }],
      domain: 'string'
    }
  }, {
    'Páginas': {
      data: [],
      domain: 'string'
    }
  }, _defineProperty({}, HAS_IMAGE_TEXT_LABEL, {
    data: [{
      value: 'false',
      label: 'Todos'
    }, {
      value: 'true',
      label: 'Sim'
    }],
    domain: 'invisible'
  }), _defineProperty({}, IS_IMAGE_MATCH_ONLY, {
    data: [{
      value: 'false',
      label: 'Todos'
    }, {
      value: 'true',
      label: 'Sim'
    }],
    domain: 'invisible'
  })];
})();